import { useEffect } from 'react';
import { isExpired, decodeToken } from "react-jwt";
import * as _ from 'lodash';
import moment from 'moment';
//import { useGlobal } from 'reactn';

import { createTimeLog } from "../api";
import { useSessionStore } from '@/_zustand/sessionStore';
import { useChatStore } from '@/_zustand/chatStore';
import { useSocket } from "@/context/ChatContext";


export const useCheckAuth = () => {
  const { status, user, handleLogin, handleLogout } = useSessionStore();
  const { room, setRoom } = useChatStore();
  const { leaveRoom } = useSocket();
  //const [setUser] = useGlobal('user');
  //const setToken = useGlobal('token')[1];

  const validateToken = async() => {
    const tokenStorage = await localStorage.getItem('token');

    if (!tokenStorage){
      //await setToken(null);
      //await setUser({});
      leaveRoom(room);
      handleLogout();
      setRoom();
      return;
    } 

    const tokenDecode = decodeToken(tokenStorage);
    const isMyTokenExpired = isExpired(tokenStorage);

    if (isMyTokenExpired){
      //await setToken(null);
      //await setUser({});
      leaveRoom(room);
      handleSubmit();
      handleLogout();
      setRoom();
      return;
    }

    let token = tokenStorage;
    let user = (!_.isUndefined(tokenDecode['user'])) ? tokenDecode['user'] : null;

    handleLogin(user, token);
  };

  const handleSubmit = async () => {
    const defaultValues = {
      user_id: user.id,
      type: 'Out',
      register_time: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    await createTimeLog(defaultValues)
  };

  useEffect(() => {
    validateToken();
  }, []);

  return status;
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { Scrollbar } from 'react-scrollbars-custom';
import * as _ from 'lodash';
import moment from 'moment';

import { ModalData } from './DataRightSide';
import { Imagesdata } from '@/constants/Images';
import { useChatStore } from '@/_zustand/chatStore';
import { useSessionStore } from '@/_zustand/sessionStore';

export const ChatSide = () => {
  const { usersOnline, usersOffline } = useChatStore();
  const { user } = useSessionStore();

  const r = <span className="avatar-status bg-success Friend-status"></span>
  const k = <span className="avatar-status bg-success notify-status"></span>

  const chat = [
    { id: 1, heading: 'New Websites is Created', data: 'CH', color: 'primary', text: '30 min ago' },
    { id: 2, heading: 'Prepare For the Next Project', data: 'N', color: 'danger', text: '2 hours ago' },
    { id: 3, heading: 'Decide the live Discussion', data: 'S', color: 'info', text: '3 hours ago' },
    { id: 4, heading: 'Meeting at 3:00 pm', data: 'K', color: 'warning', text: '4 hours ago' },
    { id: 5, heading: 'Prepare for Presentation', data: 'R', color: 'success', text: '1 day ago' },
    { id: 6, heading: 'Prepare for Presentation', data: 'MS', color: 'pink', text: '1 day ago' },
    { id: 7, heading: 'Prepare for Presentation', data: 'L', color: 'purple', text: '45 min ago' },
    { id: 8, heading: 'Prepare for Presentation', data: 'U', color: 'blue', text: '2 days ago' },
  ]

  const notification = [
    { id: 1, src1: Imagesdata("iconUser"), heading: 'Madeleine ', data: 'Hey! there I am available....', text: '3 hours ago', class: k },
    { id: 2, src1: Imagesdata("iconUser"), heading: 'Anthony', data: 'New product Launching..', text: '5 hours ago', class: '' },
    { id: 3, src1: Imagesdata("iconUser"), heading: 'Olivia', data: 'New Schedule Realease....', text: '45 minutes ago', class: k },
    { id: 4, src1: Imagesdata("iconUser"), heading: 'Madeleine', data: 'Hey! there I am available....', text: '3 hours ago', class: k },
    { id: 5, src1: Imagesdata("iconUser"), heading: 'Anthony ', data: 'New product Launching...', text: '5 hours ago', class: '' },
    { id: 6, src1: Imagesdata("iconUser"), heading: 'Olivia ', data: 'New Schedule Realease......', text: '45 minutes ago', class: k },
    { id: 7, src1: Imagesdata("iconUser"), heading: 'Olivia ', data: 'Hey! there I am available....', text: '12 minutes ago', class: k },
  ]

  const friends = [
    { id: 1, src1: Imagesdata("iconUser"), heading: 'Mozelle Belt', class: r },
    { id: 2, src1: Imagesdata("iconUser"), heading: 'Florinda Carasco', class: '' },
    { id: 3, src1: Imagesdata("iconUser"), heading: 'Alina Bernier', class: r },
    { id: 4, src1: Imagesdata("iconUser"), heading: 'Zula Mclaughin', class: r },
    { id: 5, src1: Imagesdata("iconUser"), heading: 'Isidro Heide', class: '' },
    { id: 6, src1: Imagesdata("iconUser"), heading: 'Mozelle Belt', class: r },
    { id: 7, src1: Imagesdata("iconUser"), heading: 'Florinda Carasco', class: '' },
    { id: 8, src1: Imagesdata("iconUser"), heading: 'Alina Bernier', class: '' },
    { id: 9, src1: Imagesdata("iconUser"), heading: 'Zula Mclaughin', class: '' },
    { id: 10, src1: Imagesdata("iconUser"), heading: 'Isidro Heide', class: r },
    { id: 11, src1: Imagesdata("iconUser"), heading: 'Florinda Carasco', class: '' },
    { id: 12, src1: Imagesdata("iconUser"), heading: 'Alina Bernier', class: '' },
    { id: 13, src1: Imagesdata("iconUser"), heading: 'Zula Mclaughin', class: r },
    { id: 14, src1: Imagesdata("iconUser"), heading: 'Isidro Heide', class: '' },
  ]

  const RightSideClose = () => {
    document.querySelector(".sidebar-right")?.classList.remove("sidebar-open");
  }

  return (
    <div>
      <div className="sidebar sidebar-right sidebar-animate">
        <div className="panel panel-primary card mb-0 box-shadow">
          <div className="tab-menu-heading border-0 p-3">
            <div className="card-title mb-0">Notifications</div>
            <div className="card-options ms-auto" >
              <Link to="#" className="sidebar-remove" onClick={() => RightSideClose()}><i className="fe fe-x"></i></Link>
            </div>
          </div>
          <div className='panel-body tabs-menu-body latest-tasks p-0 border-0'>
            <div className='tabs-menu '>
              <Tab.Container id="left-tabs-example" defaultActiveKey="Online">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="Online"><i className="fe fe-user-check tx-15 me-2"></i>Online</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Offline"><i className="fe fe-user-x tx-15 me-2"></i>Offline</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Chat"><i className='fe fe-message-circle tx-15 me-2'></i>Chat</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="Online">
                    <Scrollbar id='side3' style={{ height: '600px' }}>
                      <div className="list-group list-group-flush ">
                        {
                          (_.size(usersOnline) > 0) ? usersOnline.map((item) => (
                            <div className="list-group-item d-flex  align-items-center" key={Math.random()}>
                              <img className="avatar avatar-lg brround cover-image" alt='face-12' src={Imagesdata("iconUser")} />
                              <span className="avatar-status bg-success notify-status"></span>
                              <div className="ms-3">
                                <strong>{item.name}</strong> ({item.position})
                                <div className="small text-muted">
                                  Last connection {moment(item.last_activity).format('HH:mm')}
                                </div>
                              </div>
                              {
                                (user.id !== item.id) ? <ModalData /> : ''
                              }
                            </div>
                          )) : 
                          <div className="list-group-item d-flex  align-items-center">
                            <div className="ms-3">
                              <strong>No Users Online</strong>
                            </div>
                          </div>
                        }
                      </div>
                    </Scrollbar>
                  </Tab.Pane>

                  <Tab.Pane eventKey="Offline">
                    <Scrollbar id='side3' style={{ height: '600px' }}>
                      <div className="list-group list-group-flush ">
                        {
                          (_.size(usersOffline) > 0) ? usersOffline.map((item) => (
                            <div className="list-group-item d-flex  align-items-center" key={Math.random()}>
                              <img className="avatar avatar-lg brround cover-image" alt='face-12' src={Imagesdata("iconUser")} />
                              <span className="avatar-status bg-danger notify-status"></span>
                              <div className="ms-3">
                                <strong>{item.name}</strong> ({item.position})
                                <div className="small text-muted">
                                  {
                                    (item.last_activity) ? 'Last connection ' + moment(item.last_activity).fromNow() : 'Offline'
                                  }
                                </div>
                              </div>
                              {/* {
                                (user.id !== item.id) ? <ModalData /> : ''
                              } */}
                            </div>
                          )) : 
                          <div className="list-group-item d-flex  align-items-center">
                            <div className="ms-3">
                              <strong>No Users Offlina</strong>
                            </div>
                          </div>
                        }
                      </div>
                    </Scrollbar>
                  </Tab.Pane>
                  
                  <Tab.Pane eventKey="Chat">
                    <Scrollbar id='side3' style={{ height: '600px' }}>
                      {
                        chat.map((chats)=>(
                          <div className="list d-flex align-items-center border-bottom p-3"  key={Math.random()}>
                            <div className="">
                              <span className={`avatar bg-${chats.color} brround avatar-md`}>{chats.data}</span>
                            </div>
                            <Link className="wrapper w-100 ms-3" to="#">
                              <p className="mb-0 d-flex ">
                                <b>{chats.heading}</b>
                              </p>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <i className="mdi mdi-clock text-muted me-1"></i>
                                  <small className="text-muted ms-auto">{chats.text}</small>
                                  <p className="mb-0"></p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))
                      }
                    </Scrollbar>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

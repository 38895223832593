import axios from 'axios';
import { getEnvVariables } from '@/constants/getEnvVariables';
const { API_CHAT_URL } = getEnvVariables();

const getRooms = () => {
  return axios({
    method: 'post',
    url: `${API_CHAT_URL || ''}/api/rooms/list`,
    data: { limit: 30 },
  });
};

export default getRooms;

import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import * as _ from 'lodash';

import App from './layouts/App';
import Loader from './layouts/layoutcomponents/loader';
//import { RouterData } from './routes/routingdata';
import { ChatProvider } from "@/context/ChatContext";
import { getEnvVariables } from '@/constants/getEnvVariables';

import './i18n';
import './Init';
import './index.scss';

/* AUTH */
const Auth = lazy(() => import('./pages/auth/Auth'));
const AuthLogin = lazy(() => import('./pages/auth/LoginPage'));
const AuthForgotPasword = lazy(() => import('./pages/auth/ForgotPasswordPage'));
const AuthResetPassword = lazy(() => import('./pages/auth/ResetPasswordPage'));
const AccountConfirm = lazy(() => import('./pages/auth/AccountConfirmPage'));
const Error404 = lazy(() => import('./pages/error404/error404'));

/* GUEST */
const Guest = lazy(() => import('./pages/guest/Guest'));
const RequestClientPage = lazy(() => import('./pages/guest/request-client/RequestClientPage'));

/* INTRANET */
const DashboardPage = lazy(() => import('./pages/intranet/dashboard/DashboardPage'));
const BlankPage = lazy(() => import('./pages/blank/BlankPage'));
const AccountsPage = lazy(() => import('./pages/intranet/accounts/AccountsPage'));
const AccountsFormPage = lazy(() => import('./pages/intranet/accounts/AccountsFormPage'));
const CommentsPage = lazy(() => import('./pages/intranet/comments/CommentsPage'));
const PersonsPage = lazy(() => import('./pages/intranet/persons/PersonsPage'));
const TaskManagementPage = lazy(() => import('./pages/intranet/task-management/TaskManagementPage'));
const TaskManagementFormPage = lazy(() => import('./pages/intranet/task-management/TaskManagementForm'));

const TaskManagementTypePage = lazy(() => import('./pages/intranet/task-management-type/TaskManagementPage'));
const TaskManagementTypeFormPage = lazy(() => import('./pages/intranet/task-management-type/TaskManagementForm'));

const DynamicFormPage = lazy(() => import('./pages/intranet/form-builder/DynamicFormPage'));
const DynamicForm = lazy(() => import('./pages/intranet/form-builder/DynamicForm'));

const StatusPage = lazy(() => import('./pages/intranet/configuration/status/StatusPage'));
const TaskPage = lazy(() => import('./pages/intranet/configuration/task/TaskPage'));
const SubTaskPage = lazy(() => import('./pages/intranet/configuration/sub-task/SubTaskPage'));
const SitePage = lazy(() => import('./pages/intranet/configuration/sites/SitePage'));
const WorkspacePage = lazy(() => import('./pages/intranet/configuration/workspaces/WorkspacePage'));
const DepartmentsPage = lazy(() => import('./pages/intranet/configuration/departments/DepartmentPage'));
const UsersPage = lazy(() => import('./pages/intranet/configuration/users/UsersPage'));
const RolesPage = lazy(() => import('./pages/intranet/configuration/roles/RolesPage'));
const RolesAddPage = lazy(() => import('./pages/intranet/configuration/roles/RolesAdd'));
const RolesUpdPage = lazy(() => import('./pages/intranet/configuration/roles/RolesUpd'));
const PriorityPage = lazy(() => import('./pages/intranet/configuration/priority/PriorityPage'));
const TagsPage = lazy(() => import('./pages/intranet/configuration/tags/TagsPage'));
const FunctionsPage = lazy(() => import('./pages/intranet/configuration/functions/FunctionsPage'));
const PositionsPage = lazy(() => import('./pages/intranet/configuration/positions/PositionsPage'));
const TasksTypesPage = lazy(() => import('./pages/intranet/configuration/tasks-types/TasksTypesPage'));
const AccountTypesPage = lazy(() => import('./pages/intranet/configuration/account-types/AccountTypesPage'));
const SocialNetworkPage = lazy(() => import('./pages/intranet/configuration/social-network/SocialNetworkPage'));
const ExternalReportPage = lazy(() => import('./pages/intranet/external-reports/ExternalReportPage'));
const ExternalReportViewPage = lazy(() => import('./pages/intranet/external-reports/view/ExternalReportViewPage'));
const ChatPage = lazy(() => import('./pages/intranet/chat/ChatPage'));
const Welcome = lazy(() => import('./pages/intranet/chat/features/Welcome'));
const Conversation = lazy(() => import('./pages/intranet/chat/features/Conversation'));
const Meeting = lazy(() => import('./pages/intranet/chat/features/Meeting'));


//Ecommerce
const SettingPage = lazy(() => import('./pages/intranet/ecommerce/setting/SettingPage'));
const CategoriesPage = lazy(() => import('./pages/intranet/ecommerce/categories/CategoriesPage'));
const ProductsPage = lazy(() => import('./pages/intranet/ecommerce/products/ProductsPage'));
const ProductsFormPage = lazy(() => import('./pages/intranet/ecommerce/products/ProductsFormPage'));
const StoresPage = lazy(() => import('./pages/intranet/ecommerce/stores/StoresPage'));
const BrandsPage = lazy(() => import('./pages/intranet/ecommerce/brands/BrandsPage'));
const TagsEcomPage = lazy(() => import('./pages/intranet/ecommerce/tags/TagsPage'));
const TaxesPage = lazy(() => import('./pages/intranet/ecommerce/taxes/TaxesPage'));
const ManufacturersPage = lazy(() => import('./pages/intranet/ecommerce/manufacturers/ManufacturersPage'));
const PriceList = lazy(() => import('./pages/intranet/ecommerce/price-list/PriceListPage'));
const PriceListForm = lazy(() => import('./pages/intranet/ecommerce/price-list/PricesListForm'));
const OrdersPage = lazy(() => import('./pages/intranet/ecommerce/orders/OrdersPage'));

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import '@/assets/themes/bootstrap4-light-blue/theme_new.css';

export const Index = () => {
  const { DOMAIN, CHAT_BLANK_LIST, INTRANET } = getEnvVariables();
  console.log("DOMAIN", DOMAIN);
  console.log("CHAT_BLANK_LIST", CHAT_BLANK_LIST);
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route index element={<AuthLogin />} />

        {/* Authentication Routes */}
        <Route path={`/auth`} element={<Auth />}>
          <Route index element={<AuthLogin />} />
          <Route path={`/auth/forgot-password`} element={<AuthForgotPasword />} />
          <Route path={`/auth/reset-password`} element={<AuthResetPassword />} />
          <Route path={`/auth/account-confirm`} element={<AccountConfirm />} />
          <Route path={`/auth/*`} element={<Error404 route='auth' />} />
        </Route>

        {/* Guest Routes */}
        <Route path={`/guest`} element={<Guest />}>
          <Route index element={<RequestClientPage />} />
          <Route path={`/guest/request-client`} element={<RequestClientPage />} />
          <Route path={`/guest/reset-password`} element={<AuthResetPassword />} />
          <Route path={`/guest/account-confirm`} element={<AccountConfirm />} />
          <Route path={`/guest/*`} element={<Error404 route='guest' />} />
        </Route>

        {/* Intranet Routes */}
        <Route path={`${INTRANET}`} element={
          (_.includes(CHAT_BLANK_LIST, DOMAIN)) ? (
            <ChatProvider>
              <App />
            </ChatProvider>
          ) : (
            <App />
          )
          }>
          <Route index element={<DashboardPage />} />
          <Route path={`${INTRANET}/dashboard`} element={<DashboardPage /> } />
          <Route path={`${INTRANET}/accounts/:type_id?`} element={<AccountsPage /> } />
          <Route path={`${INTRANET}/accounts/:type_id?/form/:id?`} element={<AccountsFormPage /> } />
          <Route path={`${INTRANET}/files`} element={<CommentsPage /> } />
          <Route path={`${INTRANET}/persons`} element={<PersonsPage /> } />
          <Route path={`${INTRANET}/task-management`} element={<TaskManagementPage /> } />
          <Route path={`${INTRANET}/task-management/form`} element={<TaskManagementFormPage /> } />
          <Route path={`${INTRANET}/task-management/form/:id`} element={<TaskManagementFormPage /> } />
          <Route path={`${INTRANET}/task-management/type/:type_id`} element={<TaskManagementTypePage /> } />
          <Route path={`${INTRANET}/task-management/type/:type_id/form`} element={<TaskManagementTypeFormPage /> } />
          <Route path={`${INTRANET}/task-management/type/:type_id/form/:id`} element={<TaskManagementTypeFormPage /> } />
          <Route path={`${INTRANET}/status`} element={<StatusPage /> } />
          <Route path={`${INTRANET}/tasks`} element={<TaskPage /> } />
          <Route path={`${INTRANET}/subtasks`} element={<SubTaskPage /> } />
          <Route path={`${INTRANET}/workspaces`} element={<WorkspacePage /> } />
          <Route path={`${INTRANET}/priority`} element={<PriorityPage /> } />
          <Route path={`${INTRANET}/sites`} element={<SitePage /> } />
          <Route path={`${INTRANET}/departments`} element={<DepartmentsPage /> } />
          <Route path={`${INTRANET}/users`} element={<UsersPage /> } />
          <Route path={`${INTRANET}/roles`} element={<RolesPage /> } />
          <Route path={`${INTRANET}/roles/new`} element={<RolesAddPage /> } />
          <Route path={`${INTRANET}/roles/edit/:id`} element={<RolesUpdPage /> } />
          <Route path={`${INTRANET}/tags`} element={<TagsPage /> } />
          <Route path={`${INTRANET}/functions`} element={<FunctionsPage /> } />
          <Route path={`${INTRANET}/positions`} element={<PositionsPage /> } />
          <Route path={`${INTRANET}/tasks-types`} element={<TasksTypesPage /> } />
          <Route path={`${INTRANET}/account-types`} element={<AccountTypesPage /> } />
          <Route path={`${INTRANET}/social-network`} element={<SocialNetworkPage /> } />
          <Route path={`${INTRANET}/external-report`} element={<ExternalReportPage /> } />
          <Route path={`${INTRANET}/external-report-view`} element={<ExternalReportViewPage /> } />
          <Route path={`${INTRANET}/dynamic-form`} element={<DynamicFormPage /> } />
          <Route path={`${INTRANET}/dynamic-form/form`} element={<DynamicForm /> } />
          <Route path={`${INTRANET}/dynamic-form/form/:id`} element={<DynamicForm /> } />
          <Route path={`${INTRANET}/ecommerce/setting`} element={<SettingPage /> } />
          <Route path={`${INTRANET}/ecommerce/categories`} element={<CategoriesPage /> } />
          <Route path={`${INTRANET}/ecommerce/products`} element={<ProductsPage /> } />
          <Route path={`${INTRANET}/ecommerce/products/form`} element={<ProductsFormPage /> } />
          <Route path={`${INTRANET}/ecommerce/products/form/:id`} element={<ProductsFormPage /> } />
          <Route path={`${INTRANET}/ecommerce/stores`} element={<StoresPage /> } />
          <Route path={`${INTRANET}/ecommerce/brands`} element={<BrandsPage /> } />
          <Route path={`${INTRANET}/ecommerce/tags`} element={<TagsEcomPage /> } />
          <Route path={`${INTRANET}/ecommerce/taxes`} element={<TaxesPage /> } />
          <Route path={`${INTRANET}/ecommerce/manufacturers`} element={<ManufacturersPage /> } />
          <Route path={`${INTRANET}/ecommerce/prices-list`} element={<PriceList /> } />
          <Route path={`${INTRANET}/ecommerce/prices-list/form`} element={<PriceListForm /> } />
          <Route path={`${INTRANET}/ecommerce/prices-list/form/:id`} element={<PriceListForm /> } />
          <Route path={`${INTRANET}/ecommerce/orders`} element={<OrdersPage /> } />

          <Route path={`${INTRANET}/chat`} element={<ChatPage />}>
            <Route index element={<Welcome />} />
            <Route path={`${INTRANET}/chat`} element={<ChatPage /> } />
            <Route path={`${INTRANET}/chat/room/:id`} element={<Conversation /> } />
            <Route path={`${INTRANET}/chat/meeting/:id`} element={<Meeting /> } />
            <Route path={`${INTRANET}/chat/*`} element={<Error404 route='chat' />} />
          </Route>

          
          <Route path={`${INTRANET}/*`} element={<Error404 route='intranet' />} />
        </Route>

        {/* Protected Routes */}
        {/* <Route path={`${import.meta.env.BASE_URL}`} element={<App />} key={Math.random()}>
          <Route path={idx.path} element={idx.element} />
          <Route path={`${import.meta.env.BASE_URL}*`} element={<Error404 />} />
        </Route> */}
        {/* {
          RouterData.map((idx) => (
            <Route path={`${import.meta.env.BASE_URL}`} element={<App />} key={Math.random()}>
              <Route path={idx.path} element={idx.element} />
              <Route path={`${import.meta.env.BASE_URL}*`} element={<Error404 />} />
            </Route>
          ))
        }; */}
        

        {/* Landing */}
        {/* <Route path="/" element={<Landing />}>
          <Route path={`${import.meta.env.BASE_URL}landingpage`} element={<LandingPage />} />
        </Route> */}

        {/* Custom Switcher Pages */}
        {/* <Route path="/" element={<CustomSwitcher />}>
          <Route path={`${import.meta.env.BASE_URL}pages/switcher`} element={<Switcher />} />
        </Route> */}

        {/* Custom Authentication Pages */}
        {/* <Route path="/" element={<CustomAuthenticationPages />}>
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/signin`} element={<SignIn />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/signup`} element={<SignUp />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/forgotpassword`} element={<ForgotPassword />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/resetpassword`} element={<ResetPassword />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/lockscreen`} element={<LockScreen />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/underconstruction`} element={<UnderConstruction />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/error404`} element={<Error404 />} />
          <Route path={`${import.meta.env.BASE_URL}pages/custompages/error500`} element={<Error500 />} />
          <Route path={`${import.meta.env.BASE_URL}*`} element={<Error404 />} />
        </Route> */}
        <Route path={`/*`} element={<Error404 route='main' />} />
      </Routes>

    </React.Suspense>
  )
};

export default Index;
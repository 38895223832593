import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useChatStore = create()(
  persist(
    (set, get) => ({
      socket: '',
      room: '',
      users: [],
      usersOnline: [],
      usersOffline: [],
      setRoom: (socket = '', room = '') => {
        set((state) => {
          return {
            socket: socket,
            room: room,
          };
        });
      },
      setUsers: (users) => {
        set((state) => {
          return {
            users: users,
          };
        });
      },
      setUsersOnline: (users) => {
        set((state) => {
          return {
            usersOnline: users,
          };
        });
      },
      setUsersOffline: (users) => {
        set((state) => {
          return {
            usersOffline: users,
          };
        });
      },
    }),
    {
      name: "chat-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

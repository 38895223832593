window.global ||= window;

import { setGlobal } from 'reactn';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import setAuthToken from './redux/actions/setAuthToken';
import initIO from './redux/actions/initIO';
import store from './redux/store';
import { getEnvVariables } from './constants/getEnvVariables';
import version from './version.json';

const init = async () => {
  const { API_CHAT_URL } = getEnvVariables();
  
  document.addEventListener('gesturestart', (e) => {
    e.preventDefault();
  });

  if (localStorage.getItem('app') !== 'taskManager' || localStorage.getItem('version') !== version.version) {
    localStorage.clear();
    localStorage.setItem('app', 'taskManager');
    localStorage.setItem('version', version.version);
  }

  let token = localStorage.getItem('token-chat');
  let userString = localStorage.getItem('user-chat');
  let user = userString ? JSON.parse(userString) : null;

  if (token) {
    const decoded = jwtDecode(token, { complete: true });
    const dateNow = new Date();
    const isExpired = decoded.exp * 1000 < dateNow.getTime();

    let result;

    if (!isExpired) {
      try {
        const res = await axios({
          method: 'post',
          url: `${API_CHAT_URL || ''}/api/check-user`,
          data: { id: decoded.id },
        });
        result = res.data;
      } catch (e) {
        result = null;
      }
    }

    if (!result || result.error) {
      localStorage.removeItem('token-chat');
      localStorage.removeItem('user-chat');
      token = localStorage.getItem('token-chat');
      userString = localStorage.getItem('user-chat');
      user = userString ? JSON.parse(userString) : null;
    }
  }

  if (token) {
    setAuthToken(token);
    store.dispatch(initIO(token));
  }

  const state = {
    version: version.version,
    entryPath: window.location.pathname,
    token,
    user: user || (token ? jwtDecode(token) : {}),
    rooms: [],
    searchResults: [],
    favorites: [],
    meetings: [],
    nav: 'rooms',
    search: '',
    over: null,
    isPicker: false,
    messages: [],
    streams: [],
    inCall: false,
    video: true,
    audio: true,
    audioStream: null,
    videoStream: null,
    screenStream: null,
    callStatus: null,
    counterpart: null,
    callDirection: null,
    meeting: null,
    showPanel: true,
    panel: 'standard',
    newGroupUsers: [],
  };

  setGlobal(state).then(() => console.log('Global state init complete!', state));
};

export default init;

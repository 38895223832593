import React, { Fragment, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import * as _ from 'lodash';

import Header from '../layouts/layoutcomponents/header';
import Switcher from '../layouts/layoutcomponents/switcher';
//import Sidebar from '../layouts/layoutcomponents/sidebar';
import SidebarApi from '../layouts/layoutcomponents/sidebarApi';
import { ChatSide } from '../layouts/layoutcomponents/ChatSide';
import Footer from '../layouts/layoutcomponents/footer';
import BacktoTop from '../layouts/layoutcomponents/backtotop';
import { getEnvVariables } from '../constants/getEnvVariables';
import { useCheckAuth } from '@/hooks/useCheckAuth';
import { useMenus } from '@/hooks/useMenus';
import { useSocket } from "@/context/ChatContext";
import { useChatStore } from '@/_zustand/chatStore';

export const RightSideBarclose = () => {

  //rightsidebar close function
  document.querySelector(".sidebar-right").classList.remove("sidebar-open");

  //SwitcherMenu close function
  document.querySelector(".demo_changer").classList.remove("active");
  document.querySelector(".demo_changer").style.right = "-270px";

  if (document.querySelector(".card.search-result") != null) {
    document.querySelector(".card.search-result").classList.add("d-none")
  }
};

function App() {
  const { DOMAIN, CHAT_BLANK_LIST } = getEnvVariables();
  const { data, isFetching } = useMenus(1);
  const { pathname } = useLocation();
  const status = useCheckAuth();
  const navigate = useNavigate();
  const { joinRoom } = useSocket();
  const { room } = useChatStore();

  document.querySelector("body")?.classList.remove('landing-page', 'horizontalmenu');

  const SideMenuIconclose = () => {
    document.querySelector(".app")?.classList.remove("sidenav-toggled");
  }

  if(status === 'not-authenticated'){
    navigate("/auth");
  }

  useEffect(() => {
    document.querySelector("body")?.classList.remove("ltr", "bg-primary-transparent", "error-page1", "main-body", "error-2");
  }, []);

  useEffect(() => {
    localStorage.setItem('lastPatch',pathname)
    if(status === 'not-authenticated'){
      navigate("/auth");
    }
  }, [pathname]);

  if(_.includes(CHAT_BLANK_LIST, DOMAIN)){
    if(!room){
      joinRoom(DOMAIN);
    }
  }

  return (
    <Fragment>
      <div className='horizontalMenucontainer'>
        <div className="page">
          <Switcher />
          <Header />
          <div className="sticky" style={{ paddingTop: "-63px" }}>
            {/* <Sidebar /> */}
            <SidebarApi />
          </div>
          <div className="jumps-prevent" style={{ paddingTop: "63px" }}></div>
          <div className="main-content app-content" onClick={() => { RightSideBarclose(); SideMenuIconclose() }}>
            <div className="main-container container-fluid" >
              <div className='side-app'>
                <Outlet />
              </div>
            </div>
          </div>
          <Footer />
        </div>
        {
          (_.includes(CHAT_BLANK_LIST, DOMAIN)) && <ChatSide />
        }
        <BacktoTop />
      </div>
    </Fragment>
  )
}

export default App;

import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useSessionStore = create()(
  persist(
    (set) => ({
      status: "not-authenticated",
      token: '',
      user: {},
      sessionStatus: 'Offline',
      lastConnection: null,
      handleLogin: (user, token) => {
        set((state) => {
          localStorage.setItem('token',  token);
          return {
            user: user,
            token: token,
            status: "authenticated",
          };
        });
      },
      handleLogout: () => {
        set((state) => {
          localStorage.removeItem('token');
          localStorage.removeItem('token-chat');
          localStorage.removeItem('user-chat');

          return {
            user: {},
            token: '',
            sessionStatus: 'Offline',
            lastConnection: null,
            status: "not-authenticated",
          };
        });
      },
      setIdle: (sessionStatus) => {
        set((state) => {
          return {
            sessionStatus: sessionStatus,
            lastConnection: new Date().toISOString(),
          };
        });
      },
    }),
    {
      name: "session-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

import axios from 'axios';
import { getEnvVariables } from '@/constants/getEnvVariables';
const { API_CHAT_URL } = getEnvVariables();

const sendActivity = (id, status) => {
  return axios({
    method: 'post',
    url: `${API_CHAT_URL || ''}/api/activity/${id}/${status}`,
    data: {},
  });
};

export default sendActivity;
